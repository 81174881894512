// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sD d_gt d_cr";
export var heroHeaderCenter = "w_gv d_gv d_cr d_dv";
export var heroHeaderRight = "w_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "w_sF d_gq d_cv";
export var heroParagraphCenter = "w_gr d_gr d_cv d_dv";
export var heroParagraphRight = "w_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "w_sG d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "w_sH d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "w_sJ d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "w_sK d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "w_sL d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "w_q5 n_q5";
export var heroExceptionNormal = "w_q6 n_q6";
export var heroExceptionLarge = "w_q7 n_q7";
export var Title1Small = "w_qM n_qM n_qg n_qh";
export var Title1Normal = "w_qN n_qN n_qg n_qj";
export var Title1Large = "w_qP n_qP n_qg n_qk";
export var BodySmall = "w_q0 n_q0 n_qg n_qz";
export var BodyNormal = "w_q1 n_q1 n_qg n_qB";
export var BodyLarge = "w_q2 n_q2 n_qg n_qC";