// extracted by mini-css-extract-plugin
export var lbContainerOuter = "K_wj";
export var lbContainerInner = "K_wk";
export var movingForwards = "K_wl";
export var movingForwardsOther = "K_wm";
export var movingBackwards = "K_wn";
export var movingBackwardsOther = "K_wp";
export var lbImage = "K_wq";
export var lbOtherImage = "K_wr";
export var prevButton = "K_ws";
export var nextButton = "K_wt";
export var closing = "K_wv";
export var appear = "K_ww";