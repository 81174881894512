// extracted by mini-css-extract-plugin
export var alignLeft = "z_r2 d_fn d_bF d_dt";
export var alignCenter = "z_bN d_fp d_bC d_dv";
export var alignRight = "z_r3 d_fq d_bG d_dw";
export var testimonialsContainer = "z_s5 d_dV";
export var testimonialsContainerFull = "z_s6 d_dS";
export var testimonialsMainHeader = "z_jv d_jv d_v d_cv";
export var testimonialsComponentParagraph = "z_jx d_jx";
export var testimonialsComponentSmall = "z_jw d_jw";
export var testimonialsComponentsWrapper = "z_jr d_jr d_x d_v d_ct d_b2 d_b4";
export var testimonialsComponentsWrapperDesign2 = "z_js d_js d_x d_v d_ct d_b2 d_b4 d_bB";
export var testimonialsComponentsWrapperDesign3 = "z_jt d_jt d_x d_v d_G d_b4 d_bS d_by d_bH d_bN d_bC";
export var testimonialsBackgroundColor = "z_s7 d_d6";
export var colEqualHeight = "z_s8 d_by";
export var testimonialsColumnWrapper = "z_jy d_jy d_b2";
export var testimonialsImageWrapper = "z_jn d_jn d_v d_Y";
export var testimonialsImageWrapperDesign2 = "z_jp d_jp d_q d_Y d_bw";
export var testimonialsImageWrapperDesign3 = "z_jq d_jq d_Y d_by";
export var design2TextWrapper = "z_s9 d_bB d_bN d_bH";
export var design3 = "z_tb d_cC d_G";
export var imageDesign2 = "z_tc";
export var SmallSmall = "z_pk n_pk n_qg n_qD";
export var SmallNormal = "z_pl n_pl n_qg n_qF";
export var SmallLarge = "z_pm n_pm n_qg n_qB";
export var exceptionWeight = "z_r8 n_qJ";