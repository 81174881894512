// extracted by mini-css-extract-plugin
export var carouselContainer = "J_p6 d_v d_G d_bd d_Y";
export var carouselContainerCards = "J_vw J_p6 d_v d_G d_bd d_Y";
export var carouselContainerSides = "J_vx d_v d_G d_Y";
export var prevCarouselItem = "J_vy d_v d_G d_Z d_j";
export var prevCarouselItemL = "J_vz J_vy d_v d_G d_Z d_j";
export var moveInFromLeft = "J_vB";
export var prevCarouselItemR = "J_vC J_vy d_v d_G d_Z d_j";
export var moveInFromRight = "J_vD";
export var selectedCarouselItem = "J_vF d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "J_vG J_vF d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "J_vH J_vF d_v d_G d_Y d_bd";
export var nextCarouselItem = "J_vJ d_v d_G d_Z d_j";
export var nextCarouselItemL = "J_vK J_vJ d_v d_G d_Z d_j";
export var nextCarouselItemR = "J_vL J_vJ d_v d_G d_Z d_j";
export var arrowContainer = "J_vM d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "J_vN J_vM d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "J_vP J_vN J_vM d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "J_vQ J_vM d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "J_vR";
export var nextArrowContainerHidden = "J_vS J_vQ J_vM d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "J_kG d_Z";
export var prevArrow = "J_vT J_kG d_Z";
export var nextArrow = "J_vV J_kG d_Z";
export var carouselIndicatorContainer = "J_vW d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "J_vX d_v d_by d_bD";
export var carouselText = "J_vY d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "J_vZ J_vY d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "J_v0 d_b6";
export var carouselIndicator = "J_v1 J_v0 d_b6";
export var carouselIndicatorSelected = "J_v2 J_v0 d_b6";
export var arrowsContainerTopRight = "J_v3 d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "J_v4 d_Z d_bk d_bB";
export var arrowsContainerSides = "J_v5 d_Z d_bk d_bB";
export var smallArrowsBase = "J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "J_v7 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "J_v8 J_v7 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "J_v9 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "J_wb J_v9 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "J_pZ";
export var iconSmall = "J_wc";
export var multipleWrapper = "J_wd d_bB d_bD d_bd";
export var multipleImage = "J_wf d_bB";
export var sidesPrevContainer = "J_wg J_v7 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "J_wh J_v7 J_v6 d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";