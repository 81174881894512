// extracted by mini-css-extract-plugin
export var alignLeft = "r_r2 d_fn d_bF d_dt";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignRight = "r_r3 d_fq d_bG d_dw";
export var comparisonContainer = "r_r4 d_dW";
export var comparisonContainerFull = "r_r5 d_dT";
export var comparisonFloatyBox = "r_h0 d_h0 d_bx d_Y d_cy";
export var comparisonSubtitle = "r_h9 d_h9 d_v d_c4";
export var comparisonRow = "r_h5 d_h5 d_cb d_bH";
export var comparisonMainHeader = "r_h6 d_h6 d_v d_cv";
export var comparisonComponentText = "r_h2 d_h2 d_v";
export var comparisonBtnWrapper = "r_jh d_jh d_d0 d_v d_by";
export var comparisonBtnWrapperSecond = "r_jj d_jj d_v";
export var comparisonImageContainer = "r_jf d_jf d_Y d_bx d_v";
export var stretch = "r_r6";
export var limit = "r_r7";
export var exceptionWeight = "r_r8 n_qJ";