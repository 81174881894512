// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "G_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "G_fQ d_fQ d_by d_bH";
export var menuDesign6 = "G_tk d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "G_tl d_fL d_by d_dv d_bN d_bH";
export var menuRight = "G_tm d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "G_tn d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "G_tp d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "G_rC d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "G_rN d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "G_tq";
export var navbarItem = "G_rD d_bw";
export var navbarLogoItemWrapper = "G_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "G_tr d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "G_ts d_gc d_bx d_Y d_br";
export var burgerToggle = "G_tt d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "G_tv d_gc d_bx d_Y d_br";
export var burgerInput = "G_tw d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "G_tx d_f2 d_v d_G";
export var burgerLine = "G_ty d_f0";
export var burgerMenuDesign6 = "G_tz d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "G_tB d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "G_tC d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "G_tD d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "G_tF d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "G_tG d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "G_tH d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "G_tJ d_bB d_bN";
export var staticBurger = "G_tK";
export var menu = "G_tL";
export var navbarDividedLogo = "G_tM";
export var nav = "G_tN";