// extracted by mini-css-extract-plugin
export var tileContent = "x_sM d_v d_G d_Y";
export var teamTextLeft = "x_sN d_dt";
export var teamTextCenter = "x_sP d_dv";
export var teamTextRight = "x_sQ d_dw";
export var alignLeft = "x_r2 d_v d_by d_fn d_bF d_dt";
export var alignCenter = "x_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "x_r3 d_v d_by d_fq d_bG d_dw";
export var teamContainer = "x_sR d_dV";
export var teamContainerFull = "x_sS d_dS";
export var teamRowWrapper = "x_sT d_cb";
export var teamTileWrapper = "x_j2 d_j2 d_Y d_ct";
export var teamTileSquareWrapper = "x_sV d_j3 d_Y d_ct";
export var teamTileRoundWrapper = "x_j3 d_j3 d_Y d_ct";
export var teamTileNoGuttersWrapper = "x_j4 d_j4 d_Y";
export var teamHoverNoGutters = "x_j5 d_j5 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "x_kb d_kb d_bk d_v d_bx";
export var teamInfoWrapperSquare = "x_sW d_j6";
export var teamInfoWrapperRound = "x_j6 d_j6";
export var teamInfoWrapper = "x_sX d_j7 d_Z";
export var teamInfoWrapperNoGutters = "x_j8 d_j8 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "x_jY d_jY";
export var teamImgWrapperAlt = "x_jZ d_jZ";
export var teamImgWrapperNoGutters = "x_j9 d_j9";
export var teamHeader = "x_sY d_cv";
export var teamHeaderAlt = "x_sZ d_cv";
export var teamHeaderNoGutters = "x_s0 d_cv d_cC";