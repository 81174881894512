// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_s1 d_gR d_cv d_dt";
export var quoteParagraphCenter = "y_gS d_gS d_cv d_dv";
export var quoteParagraphRight = "y_gT d_gT d_cv d_dw";
export var quoteRowLeft = "y_s2 d_bF";
export var quoteRowCenter = "y_s3 d_bC";
export var quoteRowRight = "y_s4 d_bG";
export var quoteWrapper = "y_gP d_gP d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "y_gQ d_gQ";
export var quoteExceptionSmall = "y_rc n_rc";
export var quoteExceptionNormal = "y_rd n_rd";
export var quoteExceptionLarge = "y_rf n_rf";
export var quoteAuthorExceptionSmall = "y_rg n_rg";
export var quoteAuthorExceptionNormal = "y_rh n_rh";
export var quoteAuthorExceptionLarge = "y_rj n_rj";